































































































































































































import { debounce, debounceProcess } from "@/helpers/debounce";
import { Option } from "@/models/class/option.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import {
  DataResponseArListInvoiceDoc,
  IArDataList,
  IArDataResponse,
} from "@/models/interface/AccountReceivables.interface";
import {
  ContactData,
  ResponseListMaster,
} from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { DataResponseCompanyBank } from "@/models/interface/master.interface";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { IAuthorities } from "@/models/interfaces/auth.interface";
import { arService } from "@/services/ar.service";
import LocalStorageService from "@/services/LocalStorage.service";
import { masterServices } from "@/services/master.service";
import moment from "moment";
import { Component, Ref, Vue } from "vue-property-decorator";
import ReceiptTypeSelect from "./ReceiptTypeSelect.vue";

@Component({
  components: {
    "receipt-type": ReceiptTypeSelect,
  },
})
export default class ReceiptAr extends Vue {
  @Ref("searchForm") searchForm;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form = {
    branch: "",
    arReceiptNumber: "",
    customerName: "",
    receiptDate: [],
    currency: "",
    receiptType: "",
    bankName: "",
    giroName: "",
    invoiceNumber: "",
    status: "",
    invoiceARSource: "",
  };
  arReceiptNumberOption = {
    data: [] as IArDataResponse[],
    search: "",
    fetching: true,
    initialData: [] as IArDataResponse[],
  };
  dataBranch: DataWarehouseBranch[] = [];
  dataCustomerName: ContactData[] = [];
  dataBank: DataResponseCompanyBank[] = [];
  dataInvoiceNumber: DataResponseArListInvoiceDoc[] = [];
  dataStatus: ResponseListMaster[] = [];
  dataSourceInvoice: Option<ResponseListMaster>[] = [];
  tableColumns: ColumnTableCustom[] = [
    {
      title: this.$t("lbl_branch").toString(),
      dataIndex: "branchWarehouseName",
      key: "branchWarehouseName",
      width: 150,
    },
    {
      title: this.$t("lbl_invoice_number").toString(),
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      width: 150,
    },
    {
      title: this.$t("lbl_receipt_type").toString(),
      dataIndex: "receiptType",
      key: "receiptType",
      width: 150,
    },
    {
      title: this.$t("lbl_account_recievable_number").toString(),
      dataIndex: "receiptNumber",
      key: "receiptNumber",
      width: 250,
    },
    {
      title: this.$t("lbl_receipt_method").toString(),
      dataIndex: "receiptMethod",
      key: "receiptMethod",
      width: 150,
    },
    {
      title: this.$t("lbl_ar_receipt_date").toString(),
      dataIndex: "receiptDate",
      key: "receiptDate",
      scopedSlots: { customRender: "isDate" },
      width: 200,
    },
    {
      title: this.$t("lbl_customer_name").toString(),
      dataIndex: "customerName",
      key: "customerName",
      scopedSlots: { customRender: "isNull" },
      width: 250,
    },
    {
      title: this.$t("lbl_bank_name").toString(),
      dataIndex: "bankAccountName",
      key: "bankAccountName",
      width: 150,
    },
    {
      title: this.$t("lbl_currency").toString(),
      dataIndex: "currency",
      key: "currency",
      width: 150,
    },
    {
      title: this.$t("lbl_total").toString(),
      dataIndex: "receiptAmount",
      key: "receiptAmount",
      scopedSlots: { customRender: "isCurrency" },
      width: 150,
    },
    {
      title: this.$t("lbl_pph_amount").toString(),
      dataIndex: "invoiceTaxIn",
      key: "invoiceTaxIn",
      scopedSlots: { customRender: "isCurrency" },
      width: 150,
    },
    {
      title: this.$t("lbl_journal_number").toString(),
      dataIndex: "journalNo",
      key: "journalNo",
      width: 150,
      scopedSlots: { customRender: "clickColumn" },
    },
    {
      title: this.$t("lbl_status").toString(),
      dataIndex: "status",
      key: "status",
      width: 150,
    },
    {
      title: this.$t("lbl_action").toString(),
      dataIndex: "operation",
      key: "operation",
      scopedSlots: { customRender: "operation" },
      button: ["view"],
      width: 120,
      align: "center",
      fixed: "right",
    },
  ];
  pagination = {
    current: 1,
    showSizeChanger: true,
    total: 0,
    pageSize: 10,
  };
  arReceiptData = [] as IArDataResponse[];
  query: RequestQueryParamsModel = {
    limit: 10,
    page: 0,
    search: "",
    sorts: "createdDate:desc",
  };
  loadingIndicator = {
    data: false,
    download: false,
  };
  show = {
    createBtn: false,
  };

  created(): void {
    this.getInvoiceNumber = debounceProcess(this.getInvoiceNumber, 400);
    const userPrivileges: IAuthorities[] =
      LocalStorageService.loadUserPrivilege();
    const receiptArPrivilege = userPrivileges.find(
      p => p.key === "invoice-receipt"
    );
    if (receiptArPrivilege) {
      if (receiptArPrivilege.privilege.create) {
        this.show.createBtn = true;
      }
    }
    this.getArReceiptNumber(true);
    this.getBankName("");
    this.getInvoiceNumber("");
    this.getStatus("");
    this.getInvoiceARSource("");
  }

  handleGetBranch(value) {
    this.dataBranch = value;
  }

  handleGetCustomer(value) {
    this.dataCustomerName = value;
  }

  handleDownload() {
    this.loadingIndicator.download = true;
    arService
      .downloadListArReceipt(this.query)
      .then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "report_invoice_receipt_AR.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .finally(() => {
        this.loadingIndicator.download = false;
      });
  }

  checkParams(value): string {
    if (value && this.form[value]) return this.form[value];
    else return "ALL";
  }

  resetTime(value: Date, type = "start"): string {
    return moment(value)
      .set(
        type === "start"
          ? { hour: 0, minute: 0, second: 0, millisecond: 0 }
          : { hour: 23, minute: 59, second: 59, millisecond: 59 }
      )
      .format();
  }

  handleCheckParams() {
    const params: string[] = [];
    const query: string[] = [];
    const queryDownload: string[] = [];
    params.push(this.$store.state.authStore.authData.companyName);
    if (this.form.branch) {
      query.push(`branchWarehouseId~*${this.form.branch}*`);
      queryDownload.push(`branchWarehouseId~*${this.form.branch}*`);
      // params.push(`${this.dataBranch.find((dataFind) => dataFind.id === this.form['branch'])?.name}`)
    }

    if (this.form.arReceiptNumber) {
      query.push(`receiptNumber~*${this.form.arReceiptNumber}*`);
      queryDownload.push(`receiptNumber~*${this.form.arReceiptNumber}*`);
    }
    params.push(this.checkParams("arReceiptNumber"));

    if (this.form.receiptDate.length > 0) {
      query.push(
        `receiptDate>=${this.resetTime(
          this.form.receiptDate[0]
        )}_AND_receiptDate<=${this.resetTime(this.form.receiptDate[1], "end")}`
      );
      queryDownload.push(
        `receiptDate>=${this.resetTime(
          this.form.receiptDate[0]
        )}_AND_receiptDate<=${this.resetTime(this.form.receiptDate[1], "end")}`
      );
      params.push(
        `${moment(this.form.receiptDate[0]).format(
          DEFAULT_DATE_FORMAT
        )} - ${moment(this.form.receiptDate[1]).format(DEFAULT_DATE_FORMAT)}`
      );
    } else params.push("ALL");

    if (this.form.customerName) {
      query.push(`customerId~*${this.form.customerName}*`);
      queryDownload.push(`customerId~*${this.form.customerName}*`);
      params.push(
        `${this.dataCustomerName
          .find(dataFind => dataFind.id === this.form["customerName"])
          ?.fullName?.replaceAll(",", " ")}`
      );
    } else params.push("ALL");

    if (this.form.currency) {
      query.push(`currency~*${this.form.currency}*`);
      queryDownload.push(`currency~*${this.form.currency}*`);
    }

    if (this.form.receiptType) {
      query.push(`receiptType~*${this.form.receiptType}*`);
      queryDownload.push(`receiptType~*${this.form.receiptType}*`);
    }

    if (this.form.invoiceNumber) {
      query.push(`invoiceNumber~*${this.form.invoiceNumber}*`);
      queryDownload.push(`invoiceNumber~*${this.form.invoiceNumber}*`);
    }

    if (this.form.bankName) {
      query.push(`bankAccountId~${this.form.bankName}`);
      queryDownload.push(`bankAccountId~${this.form.bankName}`);
    }
    params.push(this.checkParams("bankName"));

    if (this.form.giroName) {
      query.push(`chequeNumber~*${this.form.giroName}*`);
      queryDownload.push(`chequeNumber~*${this.form.giroName}*`);
    }
    params.push(this.checkParams("giroName"));

    if (this.form.status) {
      query.push(`status~*${this.form.status}*`);
      queryDownload.push(`status~*${this.form.status}*`);
    }
    params.push(this.checkParams("status"));

    if (this.form.invoiceARSource) {
      query.push(`invoiceARSource~*${this.form.invoiceARSource}*`);
      queryDownload.push(`invoiceARSource~*${this.form.invoiceARSource}*`);
    }
    params.push(this.checkParams("invoiceARSource"));

    return {
      params,
      query,
      queryDownload,
    };
  }

  findData(type): void {
    this.searchForm.validate(valid => {
      if (!valid) {
        return;
      }
      const params: string[] = this.handleCheckParams().params;
      const query: string[] = this.handleCheckParams().query;
      const queryDownload: string[] = this.handleCheckParams().queryDownload;

      this.query.search = query.join("_AND_");
      // this.query.params = params.join(',')
      if (type === "find") {
        this.getListArReceipt();
      } else {
        this.query.search = queryDownload.join("_AND_");
        this.query.params = params.join(",");

        this.handleDownload();
      }
    });
  }

  getListArReceipt(): void {
    this.loadingIndicator.data = true;
    this.getArReceiptList(this.query)
      .then(res => {
        this.arReceiptData = res.data;
        this.pagination.total = res.totalElements;
      })
      .finally(() => (this.loadingIndicator.data = false));
  }

  getArReceiptList(query: RequestQueryParamsModel): Promise<IArDataList> {
    return arService.getArReceiptList(query);
  }

  getArReceipts(query: RequestQueryParamsModel): Promise<IArDataList> {
    return arService.getArReceipts(query);
  }

  handleClickColumn(record, _objColumnNameValue) {
    this.$router.push("/generaljournal/journal/detail/" + record.journalId);
  }
  getArReceiptNumber(firstInit = false): void {
    this.arReceiptNumberOption.fetching = true;
    const query: RequestQueryParamsModel = {
      limit: 20,
      page: 0,
      search: "",
    };
    if (this.arReceiptNumberOption.search) {
      query.search = `documentNumber~*${this.arReceiptNumberOption.search}*`;
    }
    this.getArReceipts(query)
      .then(res => {
        this.arReceiptNumberOption.data = res.data;
        if (firstInit) {
          this.arReceiptNumberOption.initialData = res.data;
        }
      })
      .finally(() => (this.arReceiptNumberOption.fetching = false));
  }

  getBankName(valueSearch) {
    const params = {
      page: 0,
      limit: 10,
    } as RequestQueryParamsModel;
    if (valueSearch)
      params.search = `bankName~*${valueSearch}*_OR_country~*${valueSearch}*_OR_bankAccNumber~*${valueSearch}*`;

    masterServices.listCompanyBank(params).then(data => {
      this.dataBank = data.data.map((dataMap, index) => {
        return { ...dataMap, key: index };
      });
    });
  }

  getInvoiceNumber(valueSearch) {
    const params = {
      page: 0,
      limit: 10,
      sorts: "createdDate:desc",
    } as RequestQueryParamsModel;
    if (valueSearch) params.documentNo = valueSearch;

    arService.getArReceiptListInvoiceDocument(params).then(response => {
      this.dataInvoiceNumber = response.data;
    });
  }

  getStatus(valueSearch) {
    const params = {
      page: 0,
      limit: 10,
      name: "INVOICE_RECEIPT_STATUS",
    } as RequestQueryParamsModel;
    if (valueSearch) params.search = `value~*${valueSearch}*`;
    masterServices.listMaster(params).then(res => {
      this.dataStatus = res;
    });
  }

  getInvoiceARSource(value: string) {
    const params = {
      page: 0,
      limit: 10,
      name: "INVOICE_SOURCE",
    } as RequestQueryParamsModel;
    masterServices.listMaster(params).then(res => {
      this.dataSourceInvoice = res.map(source => ({
        key: source.id,
        label: source.value,
        value: source.value,
        meta: source,
      }));
    });
  }

  searchReceiptNumber(value: string): void {
    debounce(() => {
      this.arReceiptNumberOption.search = value;
      this.getArReceiptNumber();
    });
  }

  resetForm(): void {
    this.searchForm.resetFields();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  handleTableChange(event): void {
    if (event.pagination.current !== this.pagination.current) {
      this.query.page = event.pagination.current - 1;
      this.pagination.current = event.pagination.current;
    }

    if (event.pagination.pageSize !== this.query.limit) {
      this.query.limit = event.pagination.pageSize;
      this.query.page = 0;
      this.pagination.pageSize = event.pagination.pageSize;
    }
    this.getListArReceipt();
  }

  createNew(): void {
    this.$router.push("/accountreceivables/receiptar/create");
  }

  viewRow(row): void {
    this.$router.push(
      `/accountreceivables/receiptar/view/${row.data.receiptId}`
    );
  }
}
